import React, { useState } from "react";
import {
  Avatar,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Text,
} from "@appsmith/wds";

import { ChatHeaderUser } from "./ChatHeaderUser";
import styles from "./styles.module.css";

// this value might come from props in future. So keeping a temporary value here.
const LOGO =
  "https://app.appsmith.com/static/media/appsmith_logo_square.3867b1959653dabff8dc.png";

interface ChatHeaderProps {
  title?: string;
  username: string;
  description?: string;
  onDeleteThread?: () => void;
}

export const ChatHeader = (props: ChatHeaderProps) => {
  const { description, onDeleteThread, title, username } = props;
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);

  return (
    <>
      <Flex alignItems="start" className={styles.header} direction="column">
        <Flex alignItems="center" gap="spacing-2">
          <Flex alignItems="center" gap="spacing-3">
            <Avatar
              className={styles.logo}
              label="Appsmith AI"
              size="large"
              src={LOGO}
            />

            <Text fontWeight={600} size="subtitle">
              {title}
            </Text>
          </Flex>
          <Button
            icon="info-square-rounded"
            onPress={() => setIsDescriptionModalOpen(true)}
            variant="ghost"
          />
        </Flex>
        <Flex gap="spacing-4">
          <ChatHeaderUser username={username} />
          <IconButton
            color="neutral"
            icon="trash"
            onPress={onDeleteThread}
            variant="ghost"
          />
        </Flex>
      </Flex>

      <Modal
        isOpen={isDescriptionModalOpen}
        setOpen={setIsDescriptionModalOpen}
      >
        <ModalContent>
          <ModalHeader title="Information about the bot" />
          <ModalBody>{description}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
