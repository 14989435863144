import React, { useRef } from "react";
import { ChatInput } from "./ChatInput";
import { Flex, Icon, Text, IconButton } from "@appsmith/wds";

import styles from "./styles.module.css";

const MIN_PROMPT_LENGTH = 3;

interface ChatInputSectionProps {
  isWaitingForResponse: boolean;
  prompt: string;
  promptInputPlaceholder?: string;
  onPromptChange: (value: string) => void;
  onSubmit?: () => void;
  onAttachFiles?: (files: FileList) => void;
}

export const ChatInputSection = (props: ChatInputSectionProps) => {
  const {
    isWaitingForResponse,
    onAttachFiles,
    onPromptChange,
    onSubmit,
    prompt,
    promptInputPlaceholder, // New prop
  } = props;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleAttachClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && onAttachFiles) {
      onAttachFiles(files);
    }
  };

  return (
    <Flex
      direction="column"
      gap="spacing-3"
      paddingBottom="spacing-3"
      paddingLeft="spacing-5"
      paddingRight="spacing-5"
    >
      <ChatInput
        isLoading={isWaitingForResponse}
        isSubmitDisabled={prompt.length < MIN_PROMPT_LENGTH}
        onChange={onPromptChange}
        onSubmit={onSubmit}
        placeholder={promptInputPlaceholder}
        prefix={
          <IconButton
            icon="paperclip"
            onPress={handleAttachClick}
            variant="ghost"
          />
        }
        value={prompt}
      />
      <input
        multiple
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: "none" }}
        type="file"
      />
      <Flex
        alignItems="center"
        className={styles.disclaimer}
        flexGrow={1}
        gap="spacing-1"
        justifyContent="center"
      >
        <Icon name="alert-circle" size="small" />
        <Text
          color="neutral-subtle"
          size="caption"
          textAlign="center"
          wordBreak="break-word"
        >
          LLM assistant can make mistakes. Answers should be verified before
          they are trusted.
        </Text>
      </Flex>
    </Flex>
  );
};
