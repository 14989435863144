import React from "react";
import { Avatar, Flex, Text } from "@appsmith/wds";
import styles from "./styles.module.css";

interface ChatUserProps {
  username: string;
}

export const ChatHeaderUser = (props: ChatUserProps) => {
  const { username } = props;

  return (
    <Flex alignItems="center" className={styles.user} gap="spacing-2">
      <Avatar label={username} />
      <Text data-testid="t--aichat-username" size="body">
        {username}
      </Text>
    </Flex>
  );
};
