import React from "react";
import { Flex } from "@appsmith/wds";

import type { ChatMessage } from "./types";
import { ChatMessageItem } from "./ChatMessageItem";

interface ChatThreadProps {
  thread: ChatMessage[];
  onApplyAssistantSuggestion?: (suggestion: string) => void;
  username: string;
  createdAt?: string;
}

export const ChatMessageList = (props: ChatThreadProps) => {
  const { createdAt, onApplyAssistantSuggestion, thread, username } = props;

  return (
    <Flex direction="column" flexGrow={1} gap="spacing-3" padding="spacing-5">
      {thread.map((message: ChatMessage) => (
        <ChatMessageItem
          createdAt={createdAt}
          key={message.id}
          message={message}
          onApplyAssistantSuggestion={onApplyAssistantSuggestion}
          username={username}
        />
      ))}
    </Flex>
  );
};
