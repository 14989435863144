import React from "react";
import { Avatar, Flex, Markdown, Text } from "@appsmith/wds";

import type { ChatMessage } from "./types";
import { ChatPromptSuggestions } from "./ChatPromptSuggestions";

interface ChatMessageItemProps {
  message: ChatMessage;
  username: string;
  createdAt?: string;
  onApplyAssistantSuggestion?: (suggestion: string) => void;
}

// this value might come from props in future. So keeping a temporary value here.
const LOGO =
  "https://app.appsmith.com/static/media/appsmith_logo_square.3867b1959653dabff8dc.png";

export const ChatMessageItem = (props: ChatMessageItemProps) => {
  const { createdAt, message, onApplyAssistantSuggestion, username } = props;
  const { content, isAssistant, promptSuggestions = [] } = message;

  const assistantMessage = (
    <Flex direction="row" gap="spacing-4">
      <Avatar label="Appsmith AI" src={LOGO} style={{ flexShrink: 0 }} />
      <Flex direction="column">
        {createdAt && (
          <Flex marginBottom="spacing-2">
            <Text color="neutral" size="caption">
              {createdAt}
            </Text>
          </Flex>
        )}
        <Markdown>{content}</Markdown>
        <ChatPromptSuggestions
          onApplyAssistantSuggestion={onApplyAssistantSuggestion}
          suggestions={promptSuggestions}
        />
      </Flex>
    </Flex>
  );

  const userMessage = (
    <Flex direction="row-reverse" gap="spacing-3">
      <Avatar label={username} style={{ flexShrink: 0 }} />
      <div>{content}</div>
    </Flex>
  );

  return (
    <Flex direction={isAssistant ? "row" : "row-reverse"} key={message.id}>
      {isAssistant ? assistantMessage : userMessage}
    </Flex>
  );
};
