import { Flex } from "@appsmith/wds";
import type { ForwardedRef } from "react";
import React, { forwardRef } from "react";

import styles from "./styles.module.css";
import { ChatHeader } from "./ChatHeader";
import type { AIChatProps } from "./types";
import { ChatMessageList } from "./ChatMessageList";
import { ChatInputSection } from "./ChatInputSection";

const _AIChat = (props: AIChatProps, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    // assistantName,
    chatDescription,
    chatTitle,
    isWaitingForResponse = false,
    onApplyAssistantSuggestion,
    onAttachFiles,
    onDeleteThread,
    onPromptChange,
    onSubmit,
    prompt,
    promptInputPlaceholder,
    thread,
    username,
    ...rest
  } = props;

  return (
    <Flex className={styles.root} direction="column" ref={ref} {...rest}>
      <ChatHeader
        description={chatDescription}
        onDeleteThread={onDeleteThread}
        title={chatTitle}
        username={username}
      />

      <ChatMessageList
        onApplyAssistantSuggestion={onApplyAssistantSuggestion}
        thread={thread}
        username={username}
      />

      <ChatInputSection
        isWaitingForResponse={isWaitingForResponse}
        onAttachFiles={onAttachFiles}
        onPromptChange={onPromptChange}
        onSubmit={onSubmit}
        prompt={prompt}
        promptInputPlaceholder={promptInputPlaceholder}
      />
    </Flex>
  );
};

export const AIChat = forwardRef(_AIChat);
