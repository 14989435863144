import React from "react";
import { Button, Flex } from "@appsmith/wds";
import type { PressEvent } from "@react-types/shared";

import styles from "./styles.module.css";

interface PromptSuggestionsProps {
  suggestions: string[];
  onApplyAssistantSuggestion?: (suggestion: string) => void;
}

export const ChatPromptSuggestions = (props: PromptSuggestionsProps) => {
  const { onApplyAssistantSuggestion, suggestions } = props;

  if (suggestions.length === 0) return null;

  const onPressSuggestion = (e: PressEvent) => {
    onApplyAssistantSuggestion?.(e.target.textContent ?? "");
  };

  return (
    <Flex
      className={styles.suggestions}
      gap="spacing-5"
      marginTop="spacing-1"
      wrap="wrap"
    >
      {suggestions.filter(Boolean).map((suggestion) => (
        <Button
          color="neutral"
          key={suggestion}
          onPress={onPressSuggestion}
          variant="subtle"
        >
          {suggestion}
        </Button>
      ))}
    </Flex>
  );
};
