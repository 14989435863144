import { ResponsiveBehavior } from "layoutSystems/common/utils/constants";
import type { WidgetDefaultProps } from "WidgetProvider/constants";

export const defaultsConfig = {
  isVisible: true,
  widgetName: "AIChat",
  widgetType: "AI_CHAT",
  version: 1,
  responsiveBehavior: ResponsiveBehavior.Fill,
  initialAssistantMessage: `Hi, welcome to the AI Assistant.

What would you like to do today?`,
  initialAssistantSuggestions: [],
  promptInputPlaceholder: "Write something...",
  assistantName: "AI Assistant",
  chatDescription:
    "This is our LLM-powered bot. You can ask it question using the natural language queries. LLM assistant can make mistakes. Answers should be verified before they are trusted.",
} as unknown as WidgetDefaultProps;
